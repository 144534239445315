import { graphql, useStaticQuery } from "gatsby"

const usePeople = function() {
  const { data } = useStaticQuery(
    graphql`
      query allContactQuery {
        data: allMarkdownRemark(
          filter: { frontmatter: { dataKey: { eq: "team" } } }
        ) {
          nodes {
            id
            fields {
              slug
            }
            frontmatter {
              uuid
              title
              name
              tel
              license
              dataKey
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    `
  )
  return data.nodes
}

export default usePeople
