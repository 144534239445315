import React from "react"

import { useInView } from 'react-intersection-observer'

export const Reveal = ({ children, className, viewClass, noViewClass, options, ...other }) => {
  const { ref, inView } = useInView({
    fallbackInView: true,
    ...options,
  })

  return (
    <div ref={ref} className={`${className ? className : ''} ${inView ? viewClass : noViewClass}`} {...other}>
      {children}
    </div>
  )
}

export const RevealFadeIn = ({ children, className, options, ...other }) => {
  const { ref, inView } = useInView({
    fallbackInView: true,
    threshold: 0.1,
    triggerOnce: true,
    ...options,
  })

  return (
    <div ref={ref} className={`${className ? className : ''} ${inView ? 'motion-safe:animate-fadeIn' : 'opacity-0'}`} {...other}>
      {children}
    </div>
  )
}

export default Reveal
