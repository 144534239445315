import React from "react"
import Image, { NoContact } from "../components/Image"
import usePeople from "../hooks/usePeople"

export const bySlug =
  Component =>
  ({ slug }) => {
    const contacts = usePeople()
    const contact = contacts.find(el => el.fields.slug === slug)
    return <Component {...contact.frontmatter} />
  }

export const byUuid =
  Component =>
  ({ uuid }) => {
    const contacts = usePeople()
    const contact = contacts.find(el => el.frontmatter.uuid === uuid)
    return <Component {...contact.frontmatter} />
  }

export const Card = ({ name, subtext, tel, image }) => {
  return (
    <div className="card-zoom w-full md:max-w-md bg-gray-100 rounded-lg border border-gray-200 shadow-lg dark:bg-gray-800 dark:border-gray-700">
      <div className="flex flex-col items-center py-10 whitespace-nowrap">
        <Image
          image={image}
          alt={`Bild ${name}`}
          className="card-zoom-image border-[3px] border-white dark:border-gray-200 overflow-hidden mb-4 w-36 h-36 rounded-full shadow text-gray-500 ease-in duration-200"
          placeholder={NoContact()}
        />
        <h3 className="text-xl mb-2 font-medium text-primary-700 dark:text-white">
          {name}
        </h3>
        <span className="text-lg mx-12 text-gray-500 dark:text-gray-400">
          {subtext}
        </span>
        <span className="text-lg mx-12 text-gray-500 dark:text-gray-400">
          {tel}
        </span>
      </div>
    </div>
  )
}

export const Contact = ({ title, name, tel, image }) => (
  <Card name={name} tel={tel} image={image} subtext={title} />
)

export const Trainer = ({ license, name, tel, image }) => (
  <Card name={name} tel={tel} image={image} subtext={license} />
)

export const InfoShort = ({ name, title, tel, image }) => {
  return (
    <div className="w-full text-gray-800 dark:text-white">
      <span className="text-lg font-medium mr-3">
        {name}
      </span> -
      <span className="text-lg mx-3">
        {title}
      </span>
    </div>
  )
}

export const InfoLong = ({ name, title, tel, image }) => {
  return (
    <div className="w-full text-gray-800 dark:text-white">
        <div className="text-lg">
          {name}
        </div>
        <div className="text-lg">
          Telefon: {tel}
        </div>
    </div>
  )
}

export default byUuid(Contact)
